import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Col, Row, Card } from "react-bootstrap"

import Img from "gatsby-image"

import MyStyles from "./index_content.module.css"

const IndexContent = () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/index_a/" } }
        ) {
          edges {
            node {
              html
              excerpt(format: MARKDOWN)
              frontmatter {
                date
                path
                title
              }
            }
          }
        }
        placeholderImage: file(relativePath: { eq: "loghi/firma.png" }) {
          childImageSharp {
            fluid(maxWidth: 600, jpegQuality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Container className="h-100" style={{
          paddingBottom:`2rem`
        }}>
          <Row className="h-100 align-items-center">
            <Col>
              <Row>
                <Col md={2}></Col>
                <Col>
                  {data.allMarkdownRemark.edges.map(({ node }, index) => (
                    <Row className="" key={index}>
                      <Col className="text-center align-items-center">
                        <Card
                          style={{
                            border: `none`,
                            background : `none`,
                          }}
                        >
                          <Card.Body>
                            <Card.Title><h2>{node.frontmatter.title}</h2></Card.Title>
                            <Card.Text
                              className={MyStyles.paragraph2}
                              dangerouslySetInnerHTML={{ __html: node.html }}
                            >
                              {/* <div dangerouslySetInnerHTML={{__html: node.html }} /> */}
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  ))}
                </Col>
                <Col md={2}></Col>
              </Row>
              <Row>
                <Col md={2}></Col>
                <Col>
                  <Row className="align-items-center">
                    <Col md={12} className="text-right">
                      IL PRESIDENTE
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col></Col>
                    <Col></Col>
                    <Col
                      xs={8}
                      sm={6}
                      md={7}
                      lg={6}
                      xl={5}
                      className="align-items-right mt-2"
                    >
                      <Img
                        fluid={data.placeholderImage.childImageSharp.fluid}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={2}></Col>
              </Row>
            </Col>
          </Row>
          {/* <div class="col-md-12 text-right">
							<img class="img-fluid w-50 mt-2" src="assets/images/loghi/firma.png" alt="">
						</div> */}
        </Container>
      </>
    )}
  />
)
export default IndexContent
