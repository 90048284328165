import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container /* , Col, Row */ } from "react-bootstrap"
import BannerCarousel from "../../components/htmls/BannerCarousel"
/* import ContentDiv from "../../components/htmls/contentBg2" */

const pathLoghi = [
  "archimede-energia-e-servizi",
  "archimede-research",
  "http://www.distrettoenergia.eu/",
  "leonardo",
  "http://www.pstsicilia.org/",
  "qohelet",
]
const IndexContent3 = () => (
  /* ...GatsbyImageSharpFluid */
  <StaticQuery
    query={graphql`
      {
        allFile(
          filter: { relativeDirectory: { eq: "index/CarouselGruppo" } }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              childImageSharp {
                fluid(maxWidth: 300, quality: 80 , jpegProgressive: true) {
                  src
                }
              }
            }
          }
          totalCount
        }
      }
    `}
    render={data => (
      <>
        <Container className="mb-4">
          <h2
            style={{
              textAlign: `center`,
              paddingTop: `2rem`,
            }}
          >
            Il Gruppo
          </h2>
          <BannerCarousel images={data.allFile.edges} paths={pathLoghi} />
        </Container>
      </>
    )}
  />
)
export default IndexContent3
