import React from "react"
import { Container, Col, Row /* , Image */ } from "react-bootstrap"

import PropTypes from "prop-types"
import Styles from "./contentBg2.module.css"

import BackgroundImage from "gatsby-background-image"

const ContentDiv = ({
  src_sfondo,
  color_text,
  backdropFilter,
  trasparenza,
  children,
  className,
  id,
}) => {
  return (
    <BackgroundImage
      id={id}
      Tag="section"
      className={`${Styles.BackgroundClass} ${className}`}
      fluid={src_sfondo}
      backgroundColor={`#040e18`}
    >
      <div
        id={id + id}
        className={`${Styles.BackgroundClass} h-100 align-item-center`}
        style={{
          backgroundColor: `${trasparenza}`,
          backdropFilter: `${backdropFilter}`,
        }}
      >
        <Container fluid>
          <Row>
            <Col
              id={id + id + id}
              className={`${Styles.DivHeader} text-center`}
              style={{
                color: `${color_text}`,
              }}
            >
              {children}
            </Col>
          </Row>
        </Container>
      </div>
    </BackgroundImage>
  )
}

ContentDiv.propTypes = {
  children: PropTypes.node.isRequired,
  src_sfondo: PropTypes.object,
  color_text: PropTypes.string,
  trasparenza: PropTypes.string,
  backdropFilter: PropTypes.string,
}

ContentDiv.defaultProps = {
  src_sfondo: {},
  color_text: "black",
  trasparenza: `"rgba(0, 0, 0, 0.6)"`,
  backdropFilter: "",
}

export default ContentDiv
