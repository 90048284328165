import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
/* import Header1 from "../components/contents/header1" */
import Header1Fluid from "../components/contents/header1Fluid"
import { Link } from "gatsby"

/* import IndexContent from "../content/index_content" */
import IndexContent2 from "../content/index/index_content2"
import IndexContent3 from "../content/index/index_content3"
/* import ContentBG from "../components/contentBg" */
/* import ContentBgMod from "../components/htmls/contentBgMod" */

/* import ContentBg2 from "../components/htmls/contentBg2" */
import ContentBg2Fluid from "../components/htmls/contentBg2Fluid"

/* import ContentBGFixed from "../components/contentBgFixed" */

/* import Image from "../components/image" */
/* import image from "loghi/Archimede.png"; */

/* const s_img = "https://source.unsplash.com/b_HIKRj7EcY/1600x900" */

import ScrollAnimation from "react-animate-on-scroll"


const IndexPage = ({ data }) => (
  <Layout
    header={
      <Header1Fluid
        src_sfondo={data.placeholderImage2.childImageSharp.fluid}
        data_img={data.placeholderImage.childImageSharp.fixed}
        regex_path_sfondo="/index/header/"
        title="Home"
        className="a1"
      />
    }
  >
    <SEO title="Home" />

    {/* <ContentBg2
        src_sfondo={s_img}
        color_text="black"
        backdropFilter="blur(6px)"
        trasparenza="rgba(255, 255, 255, 0.7)"
      >
        <IndexContent2 />
      </ContentBg2> */}
    <ContentBg2Fluid
      src_sfondo={data.ContentBg2Fluid_1.childImageSharp.fluid}
      color_text="black"
      backdropFilter="blur(6px)"
      trasparenza="rgba(255, 255, 255, 0.7)"
      id="a1"
    >
      <IndexContent2 />
    </ContentBg2Fluid>

    {/* <ContentBg2
        src_sfondo={s_img}
        color_text="white"
        backdropFilter="blur(4px)"
        trasparenza="rgba(0, 0, 0, 0.5)"
      >
        <div style={{ padding: `4rem` }}>
          <h2>Team multidisciplinare</h2>
          <p>
            <Link className="border p-1" style={{ color: `#fff` }} to="/team">
              scopri di più
            </Link>
          </p>
        </div>
      </ContentBg2> */}
    <ContentBg2Fluid
      src_sfondo={data.ContentBg2Fluid_1.childImageSharp.fluid}
      color_text="white"
      backdropFilter="blur(5px)"
      trasparenza="rgba(0, 0, 0, 0.5)"
      id="a2"
    >
      <div style={{ padding: `4rem` }}>
        <ScrollAnimation duration={0.5} animateIn="fadeIn" animateOut="fadeOut">
          <h2>Team multidisciplinare</h2>
        </ScrollAnimation>
        <ScrollAnimation duration={0.5} animateIn="bounceIn" animateOut="fadeOut" delay={500}>
          <p>
            <Link className="border p-1" style={{ color: `#fff` }} to="/team">
              scopri di più
            </Link>
          </p>
        </ScrollAnimation>
      </div>
    </ContentBg2Fluid>

    <IndexContent3 />
  </Layout>

)

export const query = graphql`
  {
    placeholderImage: file(
      relativePath: { eq: "loghi/Logo_Archimede_White.png" }
    ) {
      childImageSharp {
        fixed(width: 700) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    placeholderImage2: file(relativePath: { regex: "/index/header/" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ContentBg2Fluid_1: file(relativePath: { eq: "index/contA.jpg" }) {
      childImageSharp {
        fluid(quality: 40, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        author
        description
        title
      }
    }
  }
`

export default IndexPage
